<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Grading Level</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <!-- advance serach -->
            <div class="d-flex mb-3">
                      <div class="d-flex flex-column me-2">
                          <label class="col-form-label">Program</label>
                          <div style="width: 250px">
                              <v-select label="name" v-model="program" :options="programs" :clearable="false"
                                  :selectable="(options) => program.short_name != options.short_name "
                                  @option:selected="FilterData(program)" :loading="!programs.length">
                              </v-select>
                          </div>
                      </div>
              
                      <div class="d-flex mt-4">
                          <!-- <div class="col-form-label d-none">hfhd</div> -->
                          <div @click="refreshData()" class="icon-css">
                              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                          </div>
                      </div> 
                  </div>  
                <!--end advance serach -->

            <div class="d-flex justify-content-end">
                  <router-link :to="{ name: 'grading-level-management-create' }">
                    <button
                      v-show="!loading"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                      v-if="hasPermissions(['grading-level-create'])"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add Record
                    </button>
                  </router-link>
                </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="grading-level-management"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Program Name</th>
                    <th>Issued Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template
                    v-for="(item, index) in gradingLevel"
                    :key="index"
                  >
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ item.program_name }}</td>
                      <td>{{ new Date(item.created_at).toISOString().slice(0, 10) }}</td>
                      <td v-if="item.status == 1">
                        <button class="btn btn-success">Active</button>
                      </td>
                      <td v-else>
                        <button class="btn btn-danger">Inactive</button>
                      </td>

                      <td v-if="item.status == 1">
                        <span>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'grading-level-management-update',
                              params: { id: item.id },
                            }"
                          >
                            <i class="mdi mdi-square-edit-outline"></i>
                          </router-link>
                        </span>
                      </td>

                       <td v-else>
                        <span>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'grading-level-management-update',
                              params: { id: item.id },
                            }"
                          >
                             <button
                              type="button"
                              class="btn btn-blue waves-effect waves-light mb-2"
                            >
                              <i class="fas fa-eye"></i>
                              View
                            </button>
                          </router-link>
                        </span>
                      </td>


                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
 
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();

    return { toast };
  },
  components:{
    vSelect
  },
  data() {
    return {
      gradingLevel: [],
      programs : this.$store.getters["odoo/getAllPrograms"],
      program : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {

    async getAllGradingLevel() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/grading-level`)
        .then((response) => {
          this.gradingLevel = response.data.data;
          console.log(this.gradingLevel)
          this.loading = false;
          this.$Progress.finish();
          // console.log(this.assessment);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#grading-level-management").DataTable().draw();
    },
    FilterData(program) {
        this.clearFilter();
        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            return program?.name == data[1];
        });
        $("#grading-level-management").DataTable().draw(); 
    },
    refreshData() {
      this.program = "";
      this.clearFilter();
      this.getAllGradingLevel();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#grading-level-management").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllGradingLevel();
    let interval;
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

</style>
